(function ($) {
    var fn = ".part_ui_number";
    var $val;

    $(document).on("click", fn + " [data-number]", function (e) {
        e.preventDefault();
        var $inp = $(this).closest(fn).find("input"),
                $num;

        if ($(this).is('[data-number="plus"]')) {
            $num = +$inp.val() + $inp.data('step');
            if ($num <= $inp.attr('max')) {
                $inp.val($num).change();
            }
        } else if ($(this).is('[data-number="minus"]')) {
            $num = +$inp.val() - $inp.data('step');
            if ($num >= $inp.attr('min')) {
                $inp.val($num).change();
            }
        }
    });

    $(document).on("click", fn + " input", function () {
        $val = $(this).val();
        $(this).val("");
        $(this).blur(function () {
            var val_change = $(this).val();
            if (val_change === "") {
                $(this).val($val);
            }
        });
    });

    $(document).on("change", fn + " input", function () {
        $val = parseInt($(this).val());
        if ($val % $(this).data('step') !== 0) {
            $(this).val($val + $(this).data('step') - $val % $(this).data('step'));
        }
    });

    $(document).on('keyup keypress', fn + " input", function (e) {
        var keyCode = e.keyCode || e.which;
        if (keyCode === 13) {
            $(this).trigger('change');
            e.preventDefault();
            return false;
        }
    });
})(jQuery);