(function ($) {
    const $fn = $(".comp_product_detail");

    if ($fn.length) {
        let interval;
        interval = setInterval(function () {
            if(typeof $.fn.part_product_detail === "function") {
                clearInterval(interval);
                $fn.find(".part_product_detail").part_product_detail();
            }
        },10);
        $fn.on("click", "[data-lightgallery-toggle]", function () {
            $fn.find("[data-lightgallery-list] .elm_item:first-of-type").trigger("click");
        });
        $fn.find("[data-lightgallery-list]").lightGallery({
            thumbnail: true,
            selector: ".elm_item",
            exThumbImage: 'data-exthumbimage',
            fullScreen: false,
            zoom: true,
            actualSize: false,
            hash: false,
            download: true,
            autoplay: false,
            autoplayControls: true
        });

        $fn.find("[data-lightgallery-list]").on('onSlideItemLoad.lg',function(event, index){
            let current_img = $('.lg-current img');

            if (current_img && !current_img.attr('src').match(/is-ios/))
                current_img.attr('src', current_img.attr('src') + '?is-ios');
        });

        $fn.find("[data-lightgallery-list]").on('onAfterSlide.lg',function(event, index){
            let current = $('.lg-current');
            let video = current.find('video');

            if(video.length) {
                video[0].play();
            }
        });
    }
})(jQuery);