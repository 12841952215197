$.fn.nl_hover = function () {
    let $this = $(this);

    let timer;
    let timer_out;

    $.fn.nl_hover_out = function() {
        let $this = $(this);
        $this.addClass("toggle").removeClass("active");
        setTimeout(function () {
            $this.removeClass("toggle");
        }, 500);
    };

    $this.hover(function () {
        let $el = $(this);
        clearTimeout(timer_out);
        if ($this.not($el).hasClass("active")) {
            $this.not($el).nl_hover_out();
        }
        $el.addClass("active");
    }, function () {
        let $el = $(this);
        if ($el.hasClass("active")) {
            timer_out = setTimeout(function () {
                $el.nl_hover_out();
                clearTimeout(timer_out);
            }, 500)
        }
    });
};