(function($){
    var output = document.querySelectorAll('output')[0];

    $(document).on('input', 'input[type="range"]', function(e) {
        output.innerHTML = e.currentTarget.value;
    });

    cssLoaded(function () {
        $('input[type=range]').rangeslider({
            polyfill: false
        });
    });
})(jQuery);