$.fn.part_product_detail = function () {
    let elm = $(this);

    elm.on("click", "[data-variant-toggle]", function () {
        let toggle = $(this);
        let article = toggle.closest(".elm_item");

        elm.find("[data-variant-toggle]").not(toggle).closest(".elm_item.mod--active").toggleClass("mod--active").find(".wrp_item_detail").stop(true).slideToggle(300);
        toggle.closest(".elm_item").toggleClass("mod--active").find(".wrp_item_detail").stop(true).slideToggle(300);

        $('[data-target="ledText"]').parent().toggle(!!article.data('led-text'));
        $('[data-target="ledText"]').text(article.data('led-text'));

        $('[data-target="equivalentText"]').parent().toggle(!!article.data('equivalent-text'));
        $('[data-target="equivalentText"]').text(article.data('equivalent-text'));

        $('#product-download-energy-label').toggle(!!article.data('energy-label'));
        $('#product-download-energy-label').attr('href', article.data('energy-label'));
    });

    elm.find('[data-change-count]').on('change', function () {
        let elm_count = $(this);
        let $article = elm_count.closest('[data-article]');
        let $label = $article.find('[data-stock-label]');
        let stock = $article.data('stock');
        let unitPrice = $article.data('unit-price');
        let count = elm_count.val();

        if (stock > 0) {
            if (count > stock) {
                $label.removeClass('mod--color-new').addClass('mod--color-sale').find('i').html($label.data('over-text'));
                $article.addClass('mod-out-of-stock')
            } else {
                $label.removeClass('mod--color-sale').addClass('mod--color-new').find('i').html($label.data('default-text'));
                $article.removeClass('mod-out-of-stock')
            }
        }

        $.ajax({
            method: 'post',
            data: {do: 'calculatePrice', price: unitPrice * count},
            dataType: 'text'
        }).done(function (payload) {
            $article.find('[data-total-price]').html(payload);
        });
    });
};
