(function ($) {
    const $fn = $(".comp_site_gallery");

    if ($fn.length) {
        $fn.find("[data-lightgallery-list]").lightGallery({
            thumbnail: true,
            selector: ".part_item_gallery",
            exThumbImage: 'data-exthumbimage',
            fullScreen: false,
            zoom: true,
            actualSize: false,
            hash: false,
            download: true,
            autoplay: false,
            autoplayControls: true
        });

        $fn.find("[data-lightgallery-list]").on('onSlideItemLoad.lg',function(event, index){
            let current_img = $('.lg-current img');
            if(!current_img.attr('src').match(/is-ios/))
                current_img.attr('src', current_img.attr('src') + '?is-ios');
        });
    }
})(jQuery);