(function($) {
    $(document).on("change",".part_ui_file",function (e) {
        let input = $(this).find("input")[0];
        let textTarget = $(this).find("[data-target-text]");
        let lng = textTarget.data("target-text");

        let files = input.files;

        if(files.length > 0) {
            textTarget[0].innerHTML = `${lng["selected"]}: ${files.length}`;
        }
        else {
            textTarget[0].innerHTML = lng["empty"];
        }
    });
})(jQuery);
