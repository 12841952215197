// AJAX FORM //
$(document).on('submit', 'form.ajax', function (e) {
    e.preventDefault();
    var $frm = $(this);
    var formData = new FormData($(this)[0]);
    $.ajax({
        method: $frm.attr('method'),
        url: $frm.attr('action'),
        data: formData,
        processData: false,
        contentType: false
    });
});

// ALAX LINKS
$(document).on('click', '[href].ajax', function (e) {
    e.preventDefault();
    var $el = $(this);
    $.ajax({
        url: $el.attr('href')
    });
});

// validate dic
$(document).on('click', '[href].ajax-dic', function (e) {
    e.preventDefault();
    var $el = $(this);
    $el.addClass('mod--loading');
    $.ajax({
        method: 'post',
        url: $el.attr('href'),
        data: {'dic': $('[data-dic]').val()}
    });
});

/* GLOBAL AJAX EVENT HANDLER */
$.ajaxSetup({
    success: function (payload) {
        if (payload) {
            if (payload.redirect) {
                if (payload.redirect == 'this') {
                    setTimeout(function () {
                        location.href = window.location.href.split("#")[0];
                    }, 250);
                } else {
                    location.href = payload.redirect;
                }
            }
            if (payload.snippets) {
                for (var i in payload.snippets) {
                    $("#" + i).replaceWith(payload.snippets[i]);
                }
            }
            if (payload.replaceState) {
                window.history.replaceState(null, null, payload.replaceState.url);
            }
            if (payload.dialog) {
                nl_lib_dialog.open(payload.dialog, function () {
                    if ($("#searchCustomer").length) {
                        var options = {
                            url: function (phrase) {
                                return "?do=searchCustomer&q=" + phrase;
                            },
                            list: {
                                maxNumberOfElements: 10,
                                onClickEvent: function () {
                                    var data = $("#searchCustomer").getSelectedItemData();

                                    $.ajax({
                                        url: '/',
                                        method: 'post',
                                        data: {do: 'fakeLogin', id: data.ContactId}
                                    }).done(function () {
                                        window.location.href = location.href;
                                    });
                                }
                            },
                            getValue: "Company",
                            requestDelay: 250
                        };

                        $("#searchCustomer").easyAutocomplete(options);
                    }
                });
            }
            if (payload.title) {
                document.title = payload.title;
            }
            antispamContact();
            antispamObj();
        }
        sr.sync();
    },
    dataType: "json"
});