(function($) {
    var $timeout,
        $fn = $(".part_ui_dropdown");

    $doc.on("click",".part_ui_dropdown [aria-expanded]",function(e){
        var $el = $(this);
        e.preventDefault();
        e.stopPropagation();
        clearTimeout($timeout);
        function fn_grow_height($this) {
            if ($this.closest(".part_ui_dropdown").hasClass("mod--dynamic-height")) {
                var wrapper = $this.closest(".part_ui_dropdown").parent(),
                    growDiv = $this.closest(".part_ui_dropdown");
                wrapper.css("height",growDiv.outerHeight());
            }
        }
        $.fn.close = function() {
            var $this = $(this);
            $this.addClass("is--hiding").removeClass("mod--toggled").next("[aria-hidden]").attr("aria-hidden","true");
            $timeout = setTimeout(function(){
                $this.removeClass("is--hiding");
                $this.removeClass("is--open");
                $this.attr("aria-expanded","false");
                fn_grow_height($this);
            },300);
        };
        $.fn.open = function() {
            $(this).addClass("is--open").addClass("mod--toggled").attr("aria-expanded","true").next("[aria-hidden]").removeAttr("aria-hidden");
            fn_grow_height($(this));

            $("html").on("click.closeDropdown", function(e) {
                if (e.target.closest("[data-toggle-user-companies]") === null) {
                    $el.close();
                    $("html").off("click.closeDropdown")
                }
            });
        };

        if ($el.hasClass("is--open")) {
            $el.close();
        } else {
            $el.open();
        }
        $fn.not($el.closest($fn)).find('[aria-expanded="true"]').close();
    });
})(jQuery);
