function fn_lib_dialog() {
    var $body = $("body");
    var $dialog = $("[data-dialog]");

    function fn_lib_dialog_show(data,callback) {
        $(document).find(".lib--dialog .part_dialog").remove();
        if (!$(document).find(".lib--dialog").length) {
            $body.addClass("mod--no-scroll").append('<div class="lib--dialog"><div class="elm_dialog_background"></div></div>');
        }
        $(document).find(".lib--dialog").append(data).fadeIn(300);
        if (callback) {
            callback();
        }
    }

    function fn_lib_dialog_hide() {
        $body.removeClass("mod--no-scroll");
        $dialog.removeClass("mod--active");
        $(document).find(".lib--dialog").fadeOut(300);

        setTimeout(function () {
            $(document).find(".lib--dialog").remove();
        }, 500);
    }

    function fn_lib_dialog_init(callback) {
        $(document).on('click', '[data-dialog]', function (e) {
            e.preventDefault();
            var $el = $(this);
            $el.addClass("mod--active");

            $.ajax({
                dataType: 'json',
                url: $el.data('dialog')
            }).done(function (data) {
                fn_lib_dialog_show(data.dialog, callback);
            });
        });

        if ($body.filter("[data-dialog-open]").length) {

            let delay = 0;

            if ($body.filter("[data-dialog-open-delay]").length) {
                delay = parseInt($body.data("dialog-open-delay")) * 1000;
            }

            setTimeout(function(){
                $.ajax({
                    dataType: "json",
                    data: {ajax: +new Date},
                    url: $body.data('dialog-open')
                });
            }, delay);
        }

        $(document).on("click", ".elm_dialog_background, [data-dialog-close]", function (e) {
            e.preventDefault();
            fn_lib_dialog_hide();
        });
    }

    return {
        open: fn_lib_dialog_show,
        close: fn_lib_dialog_hide,
        init: fn_lib_dialog_init
    };
}
var nl_lib_dialog = fn_lib_dialog();