(function($) {
    if (!$("html.ie").length && !$("html.no-sr").length) {
        window.sr = ScrollReveal({ duration: 2000 });
        if (sr.isSupported()) {
            document.documentElement.classList.add('sr');
            $("section, article").each(function(s){
                var c = $(this).attr("class").split(' ')[0];
                s = s + 1;
                sr.reveal("."+c+":nth-child("+s+") [data-reveal]", {
                    duration: 1000,
                    easing: 'ease',
                    scale: null,
                    distance: 0,
                    delay: 300,
                    viewFactor : 0.2,
                    reset: false,
                    mobile: true,
                    container: document.getElementsByTagName("section"),
                    beforeReveal: function (domEl) {
                        $(domEl).attr("data-reveal","revealed");
                        let video = $(domEl).find(".part_item_product_big").find("video");
                        if(video.length) {
                            if((/iPhone/.test(navigator.userAgent) || /iPod/.test(navigator.userAgent)) === false) {
                                video[0].play();
                            }
                            else {
                                video.closest(".part_item_product_big").addClass("mod--video");

                                $(domEl).on("click",".part_item_product_big",function (e) {
                                    let video = $(this).find("video");
                                    video[0].play();
                                })
                            }
                        }
                    },
                    afterReveal: function (domEl) {
                        $(domEl).removeAttr("style");
                    }
                },0);
                $(this).find("[data-reveal-group]").each(function(i){
                    var g = $(this).attr("class").split(' ')[0],
                        speed = $(this).data("reveal-group");
                    sr.reveal("."+c+":nth-child("+s+") ."+g+" [data-reveal]", {
                        duration: speed,
                        easing: 'ease',
                        scale: null,
                        distance: 0,
                        delay: 150,
                        viewFactor : 0.2,
                        reset: false,
                        mobile: true,
                        container: document.getElementsByTagName("section"),
                        beforeReveal: function (domEl) {
                            $(domEl).attr("data-reveal","revealed");
                            let video = $(domEl).find(".part_item_product_big").find("video");
                            if(video.length) {
                                if((/iPhone/.test(navigator.userAgent) || /iPod/.test(navigator.userAgent)) === false) {
                                    video[0].play();
                                }
                                else {
                                    video.closest(".part_item_product_big").addClass("mod--video");

                                    $(domEl).on("click",".part_item_product_big",function (e) {
                                        let video = $(this).find("video");
                                        video[0].play();
                                    })
                                }
                            }
                        },
                        afterReveal: function (domEl) {
                            $(domEl).removeAttr("style");
                        }
                    },150);
                });
            });
        } else {
            document.documentElement.classList.add('no-sr');
        }
    }
})(jQuery);