(function ($) {
    const $fn = $(".comp_promo");

    if ($fn.length) {
        let video = $fn.find("video");
        let isPlaying = false;
        let videoEl = video.get(0);

        video.off("click").on("click",function (e) {
            e.preventDefault();
            e.stopPropagation();

            isPlaying = !!(videoEl.currentTime > 0 && !videoEl.paused && !videoEl.ended && videoEl.readyState > 2);

            if(!isPlaying) {
                videoEl.play();
            }
            else {
                videoEl.pause();
            }
        });

        video.on("play",function () {
            video.attr("controls",true);
            video.closest(".elm_body_video").addClass("mod--playing");
        });
        video.on("pause ended",function () {
            video.removeAttr("controls");
            video.closest(".elm_body_video").removeClass("mod--playing");
        });
    }
})(jQuery);