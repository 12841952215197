(function($) {
    if (!$html.hasClass("ie")) {
        $.fn.validation = function () {
            $(this).removeClass("mod--invalid mod--valid");
            if ($(this).find("input, textarea").val() !== "") {
                if ($(this).find("input, textarea").is(":valid")) {
                    $(this).addClass("mod--valid");
                } else {
                    $(this).addClass("mod--invalid");
                }
            }
        };

        $(document).on("change", ".part_ui_input", function () {
            if (!$(this).filter("[data-no-validation]").length) {
                $(this).validation();
            }
        });
        $(".part_ui_input").each(function () {
            if (!$(this).filter("[data-no-validation]").length && !$(this).hasClass("mod--invalid")) {
                $(this).validation();
            }
        });
    }

    $(document).on('click','.part_ui_input [data-password-show]', function (e) {
        e.stopPropagation();
        e.preventDefault();

        const elm = $(this);
        const icon = elm.find('[class*="icon--"]');
        const input = elm.closest(".part_ui_input").find("input")[0];

        if(input.type === 'password') {
            input.type = 'text';
            icon.removeClass('icon--eye');
            icon.addClass('icon--eye-slash');
        } else {
            input.type = 'password';
            icon.addClass('icon--eye');
            icon.removeClass('icon--eye-slash');
        }
    });
})(jQuery);
