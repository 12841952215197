(function ($) {
    const $fn = $(".comp_basket");

    if ($fn.length) {
        let catalog = $fn.find(".part_item_basket_catalog");
        let catalogTimeout;
        if(catalog.length) {
            clearTimeout(catalogTimeout);
            catalogTimeout = setTimeout(function () {
                catalog.slideDown();
            },2500)
        }
    }
})(jQuery);