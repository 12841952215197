(function ($) {
    let fn = ".part_ui_upload";

    $(document).on("change", fn + " input", function () {
        let elm = $(this).closest(fn);

        if(elm.find("[data-dropzone]").length) {
            return;
        }

        let input = $(this)[0],
            value = elm.find("[data-upload-value]"),
            valueText = value.data("upload-value");

        if(input.files.length > 0) {
            value[0].textContent = `${valueText}: ${input.files.length}`
        }
    });
})(jQuery);