$("#filter-form :input").on('change', function () {
    $("#filter-form").find('[name="page"]').val(1);
    $("#filter-form").find('[name="pageFrom"]').val(1);
    filterForm();
});

var filterRequest = null;
function filterForm() {
    if (filterRequest) {
        filterRequest.abort();
    }

    var data = $("#filter-form :input")
        .filter(function (index, element) {
            var elm = $(element);
            var val = elm.val();
            return val != '' && val != elm.attr('max') && val != elm.attr('min');
        })
        .serialize();

    $("#snippet-productlist").find(".wrp_comp_body").addClass("mod--loading");
    filterRequest = $.ajax({
        url: window.location.href.split('?')[0],
        data: data
    }).done(function () {
        window.history.replaceState("", "", decodeURIComponent('?' + data));
        $("#snippet-productlist").find(".wrp_comp_body").removeClass("mod--loading");

        $('html, body').animate({
            scrollTop: $('[data-append-wrapper]').offset().top - $('#layout_header').height()
        }, 800);
    });
}

$(document).on('click', '[data-append-trigger]', function (e) {
    e.preventDefault();
    var $elm = $(this);
    var $wrapper = $elm.closest('[data-append-wrapper]');
    var $input = $('#filter-form').find('[name="page"]');
    $input.val(parseInt($input.val()) + 1);
    var offset = $wrapper.find('[data-append-items]').children(".col").last().offset().top;
    $wrapper.find('[data-append-trigger]').addClass("mod--loading");

    var data = $("#filter-form :input")
        .filter(function (index, element) {
            var elm = $(element);
            var val = elm.val();
            return val != '' && val != elm.attr('max') && val != elm.attr('min');
        })
        .serialize();

    $.ajax({
        url: window.location.href.split('?')[0],
        data: data + '&append=1'
    }).done(function (payload) {
        window.history.replaceState("", "", decodeURIComponent('?' + data + '&pageFrom=1'));
        $wrapper.find('[data-append-items]').append(payload.html);
        $wrapper.find('[data-append-trigger]').removeClass("mod--loading mod--visible");

        // $('html, body').animate({
        //      scrollTop: offset - $('#layout_header').height() - 50
        // }, 0);

        if (payload.hasMore) {
            $wrapper.find('[data-more-products]').text('(' + payload.hasMore + ')');
            // $wrapper.find('[data-append-trigger]').data('page', page + 1);
        } else {
            $wrapper.find('[data-append-more]').remove();
        }

        sr.sync();
    });
});


var antispamContact = function () {
    if ($('#snipper-contactform').length) {
        setTimeout(function () {
            $('#snipper-contactform').append('<input type="hidden" class="text" name="antispam" value="antispam" />');
        }, 3000);
    }
};

if ($('#snipper-contactform').length) {
    antispamContact();
}

var antispamObj = function () {
    if ($('#snipper-objform').length) {
        setTimeout(function () {
            $('#snipper-objform').append('<input type="hidden" class="text" name="antispam" value="antispam" />');
        }, 3000);
    }
};

if ($('#snipper-objform').length) {
    antispamObj();
}

$(document).on('click', '[data-submit-basket]', function () {
    var $myForm = $('[data-basket-form]');
    $('<input type="submit">').hide().appendTo($myForm).click().remove();
});

$(document).on('click', '[data-submit-basket-final]', function () {
    var $myForm = $('[data-basket-form]');
    var elm = $(this);

    if ($myForm[0].checkValidity()) {
        elm.addClass('mod--loading');
        elm.prop('disabled', true);
    }

    $('<input type="submit">').hide().appendTo($myForm).click().remove();
});

$(document).on('change', '[data-product-compare]', function () {
    var $input = $(this);
    if ($input.prop('checked')) {
        $input.closest(".part_item_product").addClass("mod--compare-active");
        $.ajax({
            method: 'post',
            data: {do: 'addCompare', id: $input.closest('[data-product-id]').data('product-id')}
        }).done(function () {
            $("#snippet-compare-bar").parent().addClass('mod--active');
        });
    } else {
        $input.closest(".part_item_product").removeClass("mod--compare-active");
        $.ajax({
            method: 'post',
            data: {do: 'removeCompare', id: $input.closest('[data-product-id]').data('product-id')}
        }).done(function (data) {
            if (data.totalCompare > 0) {
                $("#snippet-compare-bar").parent().addClass('mod--active');
            } else {
                $("#snippet-compare-bar").parent().removeClass('mod--active');
            }
        });
    }
});

$(document).on('click', '[data-reset-compare]', function (e) {
    e.preventDefault();

    $.ajax({
        method: 'post',
        data: {do: 'resetCompare'}
    }).done(function () {
        $("#snippet-compare-bar").parent().removeClass('mod--active');
        $(document).find('.mod--compare-active').removeClass("mod--compare-active");
        $(document).find('[data-product-compare]').prop("checked", false);
    });
});

$(document).on('change', '[name="sort"]', function () {
    var text = $(this).next().text();
    $(this).closest('.part_ui_dropdown').find('button').find('i').first().text(text);
});

$(document).on('change', '.part_basket_shipping [name="country"]', function () {
    var $elm = $(this);

    $.ajax({
        method: 'post',
        data: {do: 'setCountry', value: $elm.val()}
    });
});

$(document).on('change', '.part_basket_shipping [name="payment"]', function () {
    var $elm = $(this);

    $.ajax({
        method: 'post',
        data: {do: 'setPayment', value: $elm.val()}
    }).done(function () {
        if(typeof initializePickupPoints !== 'undefined' && initializePickupPoints) {
            initializePickupPoints();
        }
    });
});

$(document).on('change', '.part_basket_shipping [name="delivery"]', function () {
    var $elm = $(this);

    $.ajax({
        method: 'post',
        data: {do: 'setDelivery', value: $elm.val()}
    }).done(function () {
        if(typeof initializePickupPoints !== 'undefined' && initializePickupPoints) {
            initializePickupPoints();
        }
    });
});

if ($('.packeta-selector-branch-id').length > 0) {
    let packetaBranchId = $('[name="packetaBranchId"]').val();
    let packetaBranchName;

    setInterval(function () {
        let newValue = $('[name="packetaBranchId"]').val();

        if (packetaBranchId !== newValue) {
            packetaBranchId = newValue;
            packetaBranchName = $('[name="packetaBranchName"]').val();

            $.ajax({
                method: 'POST',
                data: {'do' : 'setPacketa', 'id': packetaBranchId, 'name': packetaBranchName}
            });
        }
    }, 500);
}

$(document).on('input', '.part_basket_shipping [name="packetery"]', function (){
    var $elm = $(this);

    $.ajax({
        method: 'post',
        data: {do: 'setDeliveryMethod', value: $elm.val()}
    });
});

$(document).on('change', '.part_basket_content [name="count"]', function () {
    var $elm = $(this);

    $.ajax({
        method: 'post',
        data: {do: 'setProductCount', key: $elm.closest('[data-key]').data('key'), count: $elm.val()}
    });
});

$(document).on('click', '[data-basket-remove]', function (e) {
    e.preventDefault();
    var $elm = $(this);

    $.ajax({
        method: 'post',
        data: {do: 'removeProduct', key: $elm.closest('[data-key]').data('key')}
    });
});

$(document).on('click', '[data-basket-add-recommended]', function (e) {
    e.preventDefault();
    var $elm = $(this);

    $.ajax({
        method: 'post',
        data: {do: 'addProduct', productId: $elm.data('basket-add-recommended')}
    });
});

$doc.on('click', '[data-promo-confirm]', function (e) {
    e.preventDefault();

    $.ajax({
        method: 'POST',
        data: {do: 'setPromo', val: $('[data-promo-input]').val()}
    });
});

$(document).on('change', '[name="CoNumber"]', function () {
    var $elem = $(this);

    $.ajax({
        method: 'post',
        data: {do: 'getAresData', ico: $elem.val()}
    }).done(function (data) {
        if (data) {
            $('[name="Company"]').val(data.company);
            $('[name="VatNumber"]').val(data.tin);
            $('[name="Address"]').val(data.street);
            $('[name="City"]').val(data.city);
            $('[name="PostalCode"]').val(data.zip);
            $('[name="Country"]').val('CZ');
        }
    });
});

$(document).on('change', '[name="billing[ico]"]', function () {
    var $elem = $(this);

    $.ajax({
        method: 'post',
        data: {do: 'getAresData', ico: $elem.val()}
    }).done(function (data) {
        if (data) {
            $('[name="billing[company]"]').val(data.company);
            $('[name="billing[dic]"]').val(data.tin);
            $('[name="billing[street]"]').val(data.street);
            $('[name="billing[city]"]').val(data.city);
            $('[name="billing[zip]"]').val(data.zip);
            $('[name="billing[country]"]').val('CZ');
        }
    });
});

$(document).on('change', '[name="tp_radio_value"]', function () {
    var $elm = $(this);

    $.ajax({
        method: 'post',
        data: {do: 'setPaymentMethod', value: $elm.val()}
    });
});

$(document).on('change', '[data-change-country]', function() {
    $.ajax({
        method: 'post',
        data: {do: 'changeCountry', code: $(this).val()}
    });
});

$(document).ready(function () {
    var $onlinePaymentButton = $('#online-payment-button');

    if ($onlinePaymentButton.length > 0) {
        $onlinePaymentButton.find('a').addClass("part_ui_btn part_ui_btn_primary mod--lib-ripple");
        setTimeout(function () {
            $onlinePaymentButton.find('a')[0].click();
        }, 4000);
    }
});
