(function($){
    const $fn = $(".comp_product_slider");

    if ($fn.length) {
        cssLoaded(function(){
            let $slider = $fn.find("[data-slider]");
            let $slider_items = $slider[0].childElementCount;

            if((window.innerWidth >= 960 && $slider_items < 5) || (window.innerWidth < 960 && $slider_items < 4)) {
                return;
            }

            $slider.flickity({
                groupCells: '25%',
                cellAlign: 'left',
                contain: true,
                pageDots: false,
                wrapAround: true,
                autoPlay: $fn.find('[data-slider-autoplay]').data("visual-autoplay"),
                pauseAutoPlayOnHover: true
            });

            $slider.on('scroll.flickity', function() {
                $slider.addClass("mod--flickity-moving");
                $(".part_ui_shadow").remove();
            });

            $slider.on('settle.flickity', function() {
                $slider.removeClass("mod--flickity-moving");
            });

            $fn.find("[data-slider-prev]").on( 'click', function() {
                $(this).closest($fn).find("[data-slider]").flickity('previous');
            });

            $fn.find("[data-slider-next]").on( 'click', function() {
                $(this).closest($fn).find("[data-slider]").flickity('next');
            });
        });
    }
})(jQuery);