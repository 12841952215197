(function ($) {
        const $fn = $(".comp_complaint");

        if ($fn.length) {
            $fn.on('click', '[data-article-remove]', function () {
                let item = $(this).closest('[data-item]');
                item.find('[data-complaint-change]').val(0);
                item.slideUp();
            });

            $fn.on('click', '[data-article-add-button]', function () {
                let info = $('[data-article-add-info]');
                let val = $('[data-article-add-input]').val();

                val = val.toUpperCase();
                val = val.replace(/[^A-Z0-9\/]/g,'');

                let item = $(`[data-item="${val}"]`);

                info.hide();

                if (item.length > 0) {
                    item.find('[data-complaint-change]').val(1);
                    item.slideDown();
                    $('[data-article-add-input]').val('');
                    $('#reclaimDefects').show();
                } else {
                    info.show();
                }
            });

            $fn.on("input", "[data-item-add]", function () {
                var input = $(this);
                input.closest(".elm_add_single").toggleClass("mod--active", input[0].value.length > 0);
            })

            $fn.on("change","[data-add-all]", function () {
                let input = $(this);
                let checked = input.prop("checked");

                if(checked) {
                    input.closest(".elm_form_add").find(".elm_add_single").addClass("mod--hide");
                }
                else {
                    input.closest(".elm_form_add").find(".elm_add_single").removeClass("mod--hide");
                }
            })

            $fn.find("[data-dropzone]").each(function () {
                $(this).nl_dropzone();
            });
        }
    }
)(jQuery);