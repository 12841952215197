$.fn.nl_dropzone = function () {
    let elm = $(this);

    $.getScript("https://cdnjs.cloudflare.com/ajax/libs/dropzone/5.7.2/min/dropzone.min.js").done(function() {
        let files = elm.data('uploaded-files');

        new Dropzone(elm[0], {
            url: "?do=uploadFile",
            previewTemplate: "<div class=\"dz-preview dz-file-preview\"><img data-dz-thumbnail /><div class=\"dz-details\"><div class=\"dz-filename\"><span data-dz-name></span></div><div class=\"dz-size\" data-dz-size=\"Velikost:\"></div></div><div class=\"dz-progress\"><span class=\"dz-upload\" data-dz-uploadprogress></span></div><div class=\"dz-actions\"><a class=\"dz-remove\" data-dz-remove><i class=\"icon icon--close\"></i></a></div></div>",
            init: function () {
                let self = this;

                $(files).each(function (index, file) {
                    let mockFile = {name: file.name, size: file.size, filePath: file.filePath};
                    self.displayExistingFile(mockFile, "/_temp/" + file.filePath);
                });

                this.on("removedfile", function (file) {
                    $.ajax({
                        url: "?do=removeFile",
                        method: 'post',
                        data: {'filePath': file.filePath}
                    });
                });

                this.on("success", function (file, response) {
                    file.filePath = response.filePath;
                });
            }
        });
    });
}