(function($){
    const $fn = $(".comp_visual");

    if ($fn.length) {
        cssLoaded(function() {
            let $carousel = $fn.find('[data-visual-slider]'),
                $caption = $fn.find('[data-visual-caption]'),
                $autoplay = $fn.find('[data-visual-autoplay]').data("visual-autoplay"),
                // $autoplay = 2000,
                $videos = $carousel.find("video"),
                $timeout,
                manualPause = false,
                pauseInterval,
                // isiOS = (/iPhone/.test(navigator.userAgent) || /iPad/.test(navigator.userAgent));
                isiOS = false;

            $caption.flickity({
                contain: true,
                pageDots: false,
                wrapAround: true,
            });

            $carousel.flickity({
                imagesLoaded: false,
                percentPosition: false,
                setGallerySize: false,
                pageDots: true,
                wrapAround: true,
                contain: true,
                sync: '[data-visual-caption]',
                autoPlay: $autoplay,
                pauseAutoPlayOnHover: true
            });

            let flkty = $carousel.data('flickity');

            let carousel_img = $carousel.find('.part_ui_image'),
                docStyle = document.documentElement.style,
                transformProp = typeof docStyle.transform === 'string' ? 'transform' : 'WebkitTransform';

            if(isiOS === false) {
                if($(flkty.slides[0].cells[0].element).find("video").length) {
                    $carousel.flickity("stopPlayer");
                    let video = $(flkty.slides[0].cells[0].element).find("video");
                    video[0].play();
                    video[0].oncanplay = function() {
                        nextVideo(parseInt(video[0].duration));
                    };
                }
            }

            $carousel.on('scroll.flickity', function () {
                flkty.slides.forEach(function (slide, i) {
                    let img = carousel_img[i],
                        x = (slide.target + flkty.x) * -1;

                    img.style[transformProp] = 'translateX(' + Math.round(x) + 'px)';
                });
            });

            $caption.on({
                mouseenter: function () {
                    $carousel.flickity("stopPlayer");
                    manualPause = true;
                },
                mouseleave: function () {
                    $carousel.flickity("playPlayer");
                    manualPause = false;
                }
            });

            $carousel.on({
                mouseenter: function () {
                    manualPause = true;
                },
                mouseleave: function () {
                    manualPause = false;
                }
            });

            if(isiOS === true) {
                $videos.each(function () {
                    $(this).closest(".elm_item").addClass("mod--video");
                });

                $fn.on("click",".mod--video",function (e) {
                    let el = $(this);
                    let video = el.find("video");

                    el.removeClass("mod--video");
                    video[0].play();
                    $carousel.flickity('stopPlayer');
                    video[0].oncanplay = function() {
                        nextVideo(parseInt(video[0].duration));
                    };
                })
            }

            $carousel.on("select.flickity",function(event, index){
                    clearTimeout($timeout);

                    let video = $(flkty.cells[index].element).find("video");
                    if(video.length) {
                        if(!video.closest(".elm_item").hasClass("mod--video")) {
                            $carousel.flickity('stopPlayer');
                            if(isiOS === false) {
                                video[0].play();
                            }
                            video[0].oncanplay = function() {
                                nextVideo(parseInt(video[0].duration));
                            };
                        }
                    }
            });

            function nextVideo(duration) {
                clearTimeout($timeout);
                $timeout = setTimeout(function () {
                    if($window.width() < 1025) {
                        manualPause = false;
                    }
                    if(!manualPause) {
                        $carousel.flickity('playPlayer');
                        $carousel.flickity('next');
                    }
                    else {
                        clearInterval(pauseInterval);
                        pauseInterval = setInterval(function () {
                            if(!manualPause) {
                                clearInterval(pauseInterval);
                                $carousel.flickity('playPlayer');
                                $carousel.flickity('next');
                            }
                        },100);
                    }
                }, duration * 1000);
            }
        });
    }
})(jQuery);