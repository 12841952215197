(function ($) {
    const $fn = $(".comp_landing");

    if ($fn.length) {
        let comp_body = $fn[0].querySelector("[data-pageable]");

        if(comp_body !== null) {
            let anchors = [];
            let sections = [];
            let currentSection;

            [...comp_body.querySelectorAll("[data-anchor-elm]")].forEach((section, index)=>{
                anchors.push(section.dataset.anchorElm);
                sections[index] = {
                    element: section,
                    hash: section.dataset.anchorElm,
                    top: section.offsetTop - 100,
                    bottom: section.offsetTop + section.clientHeight,
                    backgroundItems: [...section.querySelector(".elm_section_background").querySelectorAll(".part_ui_image")],
                    timeout: ""
                }
            });

            let nav = $fn[0].querySelector("[data-ref-nav]");

            function changeNav(hash) {
                let active = nav.querySelector(".mod--active");
                if(active !== null) {
                    active.classList.remove("mod--active");
                }
                let currentItem = nav.querySelector(`[href="#${hash}"]`)
                if(currentItem !== null) {
                    currentItem.classList.add("mod--active");
                    nav.scrollLeft = currentItem.offsetLeft - 48;
                }
            }

            function startImages(section) {
                clearTimeout(section.timeout);
                if(section.backgroundItems.length > 1) {
                    section.timeout = setInterval(()=>{
                        let active = section.element.querySelector(".elm_section_background").querySelector(".mod--active");
                        let next = section.element.querySelector(".elm_section_background").querySelector(".mod--next");

                        if(active !== null) {
                            active.classList.remove("mod--active");
                        }
                        if(next !== null) {
                            next.classList.remove("mod--next");
                            next.classList.add("mod--active");

                            setTimeout(()=>{
                                let newNext = next.nextElementSibling;
                                if(newNext !== null) {
                                    newNext.classList.add("mod--next");
                                }
                                else {
                                    section.backgroundItems[0].classList.add("mod--next");
                                }
                            },500);
                        }
                    },3000);
                }
            }

            if(window.innerWidth >= 960 && window.innerHeight >= 720) {
                $.getScript("https://cdn.jsdelivr.net/npm/pageable@0.6.8/dist/pageable.min.js").done(function() {

                    [...nav.querySelectorAll("[data-anchor]")].forEach(item=>{
                        item.removeAttribute("data-anchor");
                    });

                    let pageable = new Pageable(comp_body, {
                        childSelector: "[data-anchor-elm]",
                        anchors: anchors,
                        freeScroll: false,
                        onInit: data => {
                            startImages(sections[0]);
                        },
                        onStart: data => {
                            comp_body.classList.remove("mod--init");
                            changeNav(data);
                        },
                        onFinish: data => {
                            startImages(sections[data.index]);
                        }
                    });

                    window.addEventListener("resize",() => {
                        if (window.innerWidth < 960 || window.innerHeight < 720) {
                            pageable.destroy();
                        }
                    })
                });
            }
            else {
                window.addEventListener("scroll", () => {
                    let currentScroll = window.scrollY;

                    sections.forEach(section => {
                        if (currentScroll >= section.top && currentScroll < section.bottom && currentSection !== section.element) {
                            currentSection = section.element;
                            changeNav(section.hash);
                        }
                    })
                });

                sections.forEach(section => {
                    startImages(section);
                })
            }
        }
    }
})(jQuery);