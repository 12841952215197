(function ($) {
    const $fn = $(".comp_gallery_video");

    if ($fn.length) {
        $fn.find("[data-lightgallery-list]").lightGallery({
            selector: ".part_item_gallery_category",
            exThumbImage: 'data-exthumbimage',
            zoom: false,
            actualSize: false,
            autoplay: false,
            autoplayControls: false,
            fullScreen: false,
            loadYoutubeThumbnail: false,
        });
    }
})(jQuery);