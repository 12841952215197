(function($){
    const $fn = $(".comp_product_category");

    if ($fn.length) {
        $fn.on("click", "[data-filter-toggle]", function (e) {
            e.preventDefault();
            let $el = $(this);
            $fn.find(".wrp_comp_head [data-filter-toggle]").toggleClass("mod--active");
            $el.closest($fn).find(".wrp_comp_filter").slideToggle(300).toggleClass("mod--active");
        });

        $fn.on("click", "[data-filter-clear]", function (e) {
            e.preventDefault();
            let $el = $(this);
            $el.closest(".wrp_comp_filter").find("input[type=checkbox]").prop("checked",false).trigger("change");
        });

        $fn.on("click", '[data-paginator] a[data-page]', function(e) {
            e.preventDefault();
            let $el = $(this);
            $("#filter-form").find('[name="page"]').val($el.data('page'));
            filterForm();
        });

        $fn.on("click","[data-dropdown]",function (e) {
            let el = $(this),
                sub = el.next("ul");

            if(el.hasClass("mod--active")) {
                sub.stop().slideUp(function () {
                    el.removeClass("mod--active");
                })
            }
            else {
                el.addClass("mod--opening");
                sub.stop().slideDown(function () {
                    el.addClass("mod--active");
                    el.removeClass("mod--opening");

                    $("html").on("click",function (e) {
                        if(!$(e.target).closest(".elm_dropdown").length) {
                            sub.stop().slideUp(function () {
                                el.removeClass("mod--active");
                            })
                        }
                    })
                })
            }
        });

        $fn.on("click","[data-dropdown-sub]",function (e) {
            let el = $(this),
                sub = el.next("ul");

            if(el.hasClass("mod--active")) {
                sub.stop().slideUp(function () {
                    el.removeClass("mod--active");
                })
            }
            else {
                el.addClass("mod--opening");
                el.closest("li").siblings("li").find("ul").stop().slideUp(function () {
                    $(this).closest("li").find(".mod--active").removeClass("mod--active");
                });
                sub.stop().slideDown(function () {
                    el.addClass("mod--active");
                    el.removeClass("mod--opening");
                })
            }
        })

        $fn.on("click","[data-view-toggle]",function () {
            let elm = $(this);
            elm.closest("[data-ref-view]").find(".mod--active").removeClass("mod--active")
            elm.addClass("mod--active");
        })

        const categoriesSlider = $fn.find('[data-target="filterCategories"]')

        if(categoriesSlider.length) {
            categoriesSlider.flickity({
                groupCells: false,
                cellAlign: 'center',
                contain: true,
                pageDots: false,
                wrapAround: true,
                autoPlay: false,
                prevNextButtons: false,
                initialIndex: '.is-initial-select'
            })
        }

        $fn.on("click",'[data-head-expand]',function (e) {
            e.preventDefault;

            const elm = $(this);
            const expandTarget = $fn.find('[data-target="headExpand"]')

            if(expandTarget.hasClass("mod--active")) {
                elm.removeClass("mod--active")
                expandTarget.animate({
                    height: '0px'
                },{
                    duration: 300,
                    complete: function () {
                        expandTarget.removeClass("mod--active")
                    }
                })
            } else {
                elm.addClass("mod--active")
                expandTarget.animate({
                    height: expandTarget[0].scrollHeight + 'px'
                },{
                    duration: 300,
                    complete: function() {
                        expandTarget.addClass("mod--active")
                    }
                })
            }
        })
    }
})(jQuery);
