(function ($) {
    var $fn = $("#layout_header"),
            $header = $fn.find(".wrp_header_body"),
            $header_nav = $fn.find(".wrp_header_nav"),
            $header_search = $fn.find(".wrp_header_search"),
            $header_search_results = $fn.find(".wrp_header_search_results");

    if ($fn.length) {
        var $layout_nav = $(document).find("#layout_nav")

        $header_nav.find(".mod--sub").nl_hover();

        $header_nav.find(".mod--images").nl_hover();

        function fitImagesToScreen() {
            $header_nav.find(".elm_nav_images").each(function () {
                let el = $(this);
                el.removeClass("mod--shift-left mod--shift-right");
                if(el.offset().left < 0) {
                    el.addClass("mod--shift-right");
                }
                if(el.offset().left + el.outerWidth() > $window.width()) {
                    el.addClass("mod--shift-left");
                }
            });
        }

        fitImagesToScreen();

        $window.on("resize",debounce(function () {
            fitImagesToScreen();
        },250));

        $header_nav.find("[data-menu-item]").each(function () {
            let elm = $(this).find("i");
            let inner = elm.find("span");

            if (inner.text().length > 15) {
                elm.css("font-size", "0.6875em");
            }
            if (inner.width() > elm.width()) {
                elm.css("font-size", "0.6875em");
            }
            if (inner.width() > elm.outerWidth()) {
                elm.css("font-size", "0.5625em");
            }
        });

        $.getScript($cdnjs.touchswipe).done(function () {
            $('.ssm-nav').slideAndSwipe();
        });

        $.getScript($cdnjs.headroom).done(function () {
            var headroom = new Headroom($fn[0], {
                offset: 5,
                tolerance: {
                    up: 5,
                    down: 0
                },
                classes: {
                    "initial": "headroom",
                    "pinned": "headroom--pinned",
                    "unpinned": "headroom--unpinned",
                    "top": "headroom--top",
                    "notTop": "headroom--not-top"
                },
                onUnpin : function() {
                    $header_search.fadeOut(150);
                    $header.find(".part_ui_dropdown > button").removeClass("mod--toggled");
                    $header.find(".elm_basket").removeClass("mod--active");
                    $header.find(".part_basket_dropdown").removeClass("mod--active");
                }
            });
            headroom.init();
        });

        $layout_nav.find("[data-redirect-extranet]").removeAttr("aria-expanded");
        $layout_nav.on("click", "[data-redirect-extranet]", function (e) {
            window.location.href = $(this).data("redirect-extranet");
        });

        let searchRequest = null;
        $(document).on("input", "[data-search-autocomplete]", function () {
            searchRequest ? searchRequest.abort() : null;

            let $results_data = $header_search_results.find("[data-results-product]");
            let query = $(this).val();
            let lang = $('html').attr('lang');
            let url = '/';
            $('[data-results-product-all]').hide();
            let timeoutResults;

            if (lang !== 'cs') {
                url += lang + '/';
            }

            $results_data.html("");
            $header_search.find(".icon--search").addClass("mod--loading");
            searchRequest = $.ajax({
                url: url,
                method: 'post',
                dataType: 'json',
                data: {do: 'search', q: query}
            }).done(function (data) {
                $results_data.html("");
                $header_search_results.fadeIn(300);
                $header_search.find(".icon--search").removeClass("mod--loading");
                $('[data-results-product-all]').removeClass("is--animated");
                if (typeof data.products === 'undefined') {
                    $results_data.append(`
                        <div class="col">
                            <div class="elm_info" style="padding: 2em;">${data.lng_not_found}</div>
                        </div>
                    `)
                } else {
                    data.products.forEach(function (item) {
                        let price = "";
                        if(item.price_original !== item.price) {
                            price = `
                                <div class="elm_info_price" style="font-size: 80%; color: #bbb; text-decoration: line-through; font-weight: 500">
                                    <i><span>${item.price_original}</span></i>
                                </div>
                                <div class="elm_info_price">
                                    <i><span style="background-color: #fc6e51; color: #ffffff; padding: 0.25rem 0.75rem; font-size: 0.75rem; margin-top: 0.25rem; display: block;">${item.price}</span></i>
                                </div>
                            `
                        }
                        else {
                            price = `
                                <div class="elm_info_price">
                                    <i><span>${item.price}</span></i>
                                </div>                            
                            `
                        }
                        $results_data.append(`
                            <div class="col col--2 col--4-t col--12-m" data-search-item data-product-id="${item.code}">
                                <a href="${item.uri}" class="part_item_product_small mod--lib-ripple">
                                    <div class="wrp_item_body mod--lib-ripple">
                                        <div class="elm_body_image mod--ratio" data-ratio="1/1">
                                            <img src="${item.image}" alt class="lazyload" />
                                        </div>
                                    </div>
                                    <div class="wrp_item_info">
                                        <div class="elm_info_title">
                                            <h3 class="part_ui_title"><i>${item.name}</i></h3>
                                        </div>
                                        ${price}
                                    </div>
                                </a>
                            </div>
                        `)
                    });

                    if (data.hasOwnProperty("category")) {
                        $results_data.append(`
                            <div class="col col--2 col--4-t col--12-m">
                                <a href="${data.category.uri}" class="elm_results_detail mod--lib-ripple" title="">
                                    <div class="elm_detail_icon">
                                        <i class="icon icon--category-${data.category.id}"></i>
                                    </div>
                                    <div class="elm_detail_text">
                                        <span>${data.category.count}</span>
                                        <strong>${data.category.text}</strong>
                                    </div>
                                </a>
                            </div>
                        `);
                    }
                }

                if (data.total > 0) {
                    $('[data-results-product-all]').find('span').text(data.total);
                    $('[data-results-product-all]').addClass("is--animated").show();
                    clearTimeout(timeoutResults);
                    timeoutResults = setTimeout(function () {
                        $('[data-results-product-all]').removeClass("is--animated");
                    },1500);
                } else {
                    $('[data-results-product-all]').hide();
                }

                if (query.length === 0) {
                    $header_search_results.hide();
                } else {
                    $header_search_results.show();
                }
            });
        });

        $(document).on("keypress", "[data-search-autocomplete]", function (e) {
            if (e.which == 13) {
                var $elm = $(this);
                var totalFound = $('[data-results-product]').find('[data-search-item]').length;

                if (totalFound == 1) {
                    var url = $('[data-results-product] [data-search-item] a').attr('href');
                    window.location.href = url;
                } else if (totalFound > 1) {
                    var url = $('#layout_header .elm_nav a').first().attr('href');
                    window.location.href = url + '?q=' + $elm.val();
                }
            }
        });

        $(document).on("click", "[data-toggle-search]", function () {
            $header_search.fadeIn(300);
            $header_search.find('input[type="text"]').focus();

            $html.on("click", function (e) {
                if ($(e.target).closest('#layout_header').length === 0) {
                    $header_search.fadeOut(300);
                    $header_search.find('input[type="text"]').val("").blur();
                    $header_search_results.fadeOut();
                    $html.off('click');
                }
            });
        });

        $(document).on("click", "[data-close-search]", function (e) {
            e.preventDefault();
            $header_search.fadeOut(300);
            $header_search.find('input[type="text"]').val("").blur();
            $header_search_results.fadeOut();
            $html.off('click');
        });

        $(document).on("click", "[data-toggle-basket]", function (e) {
            let $el = $(this);
            e.preventDefault();
            e.stopPropagation();

            $html.on("click", function (e) {
                if ($(e.target).closest('.part_basket_dropdown').length === 0 && $(e.target).closest(".elm_basket").length === 0) {
                    $el.removeClass("mod--active").next(".part_basket_dropdown").removeClass("mod--active");
                    $html.off('click');
                }
            });

            if ($el.hasClass("mod--active")) {
                $el.removeClass("mod--active").next(".part_basket_dropdown").removeClass("mod--active");
                $html.off('click');
            } else {
                $el.addClass("mod--active").next(".part_basket_dropdown").addClass("mod--active");
            }
        });

        $(document).on("click", "[data-toggle-type]", function (e) {
            e.preventDefault();
            e.stopPropagation();

            let el = $(this);

            if(el.hasClass("mod--active")) {
                return;
            }

            el.closest(".elm_nav_switcher").find(".part_ui_btn").toggleClass("mod--active");

            let menu_active = el.closest(".wrp_header_nav").find(".elm_nav_block.mod--active");
            let menu_inactive = el.closest(".wrp_header_nav").find(".elm_nav_block:not(.mod--active)");

            menu_active.fadeOut(150, function () {
                menu_active.removeClass("mod--active");
                menu_inactive.fadeIn(150, function () {
                    menu_inactive.addClass("mod--active");
                    fitImagesToScreen();
                })
            });

            $.ajax({
                method: 'post',
                data: {do: 'menuType', value: el.data('toggle-type')}
            });
        });

        $(document).on('click', '[data-results-product-all]', function (e) {
            e.preventDefault();

            var event = jQuery.Event("keypress");
            event.which = 13;
            event.keyCode = 13;
            $("[data-search-autocomplete]").trigger(event);
        });

        $(document).on("click","[data-toggle-user-companies]", function (e) {
            e.preventDefault()
            e.stopPropagation()

            $(this).toggleClass("mod--active")
            $header.find("[data-ref-user-companies]").stop().slideToggle()
        })

        $header_search_results[0].addEventListener("mousewheel", function (e){
            e.preventDefault();
            e.stopPropagation();
            return false;
        });

        $(document).on("keydown", function (e) {
            if(e.which === 27) {
                $header_search_results.stop().hide()
            }
        })
    }
})(jQuery);
