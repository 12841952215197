(function($){
    const $fn = $(".part_extranet_table");

    if ($fn.length) {
        if($fn.find('[data-action="tippy"]').length > 0) {
            $.getScript("https://cdn.jsdelivr.net/npm/@popperjs/core@2.11.6/dist/umd/popper.min.js").done(function () {
                $.getScript("https://cdn.jsdelivr.net/npm/tippy.js@6.3.7/dist/tippy-bundle.umd.min.js").done(function () {
                    initTippy();
                })
            })
        }
    }
})(jQuery);

function initTippy() {
    const $fn = $(".part_extranet_table");

    $fn.find('[data-action="tippy"]').each(function () {
        const elm = $(this);
        const position = elm.data('position') === 'center' ? 'bottom' : 'bottom-start'
        const positionFlip = elm.data('position') === 'center' ? 'top' : 'top-start'
        const row = elm.closest('tr');

        const options = {
            content: '',
            placement: position,
            trigger: 'click',
            interactive: true,
            appendTo: 'parent',
            arrow: false,
            inertia: true,
            allowHTML: true,
            showOnCreate: true,
            offset: [0,0],
            maxWidth: 1366,
            popperOptions: {
                strategy: 'fixed',
                modifiers: [
                    {
                        name: 'flip',
                        options: {
                            fallbackPlacements: [positionFlip],
                            boundary: $fn[0]
                        },
                    },
                ]
            },
            onShow: function () {
                $fn.addClass('has--tippy')
                row.addClass('has--tippy')
                $fn[0].style.setProperty('--active-row', row[0].rowIndex - 1);

                if((row[0].rowIndex - 1) % 2 === 0) {
                    $fn.addClass('mod--odd')
                } else {
                    $fn.removeClass('mod--odd')
                }
            },
            onHide: function () {
                $fn.removeClass('has--tippy')
                row.removeClass('has--tippy')
            }
        }

        elm.one('click', function(e) {
            elm[0].style.cursor = 'wait'

            $.ajax({
                url: elm.data('action-url'),
                success: function (payload) {
                    options.content = payload.content
                    elm[0].style.cursor = null

                    tippy(elm[0],options)
                }
            });
        })
    })
}
