(function ($) {
    const $fn = $(".comp_product_editor");

    if ($fn.length) {
        cssLoaded(function () {
            let $slider = $fn.find("[data-slider]");

            function fn_slider() {
                $slider.each(function () {
                    let $elm = $(this);
                    if ($(this).hasClass("flickity-enabled")) {
                        $(this).flickity('destroy');
                    }
                    if ($(this).children(".col").length > 3 || $window.width() < 768 || ($window.width() > 959 && $window.width() < 1280 && $(this).children(".col").length > 2)) {
                        $(this).flickity({
                            groupCells: '25%',
                            cellAlign: 'left',
                            contain: true,
                            pageDots: false,
                            wrapAround: true,
                            autoPlay: false,
                            prevNextButtons: false
                        });
                        $(this).closest(".elm_item").find("[data-slider-prev], [data-slider-next]").show();
                    } else {
                        $(this).closest(".elm_item").find("[data-slider-prev], [data-slider-next]").hide();
                    }
                });
            }
            fn_slider();
            $window.on("resize", fn_slider);

            $fn.find("[data-slider-prev]").on("click", function () {
                $(this).closest(".elm_item").find("[data-slider]").flickity('previous');
            });

            $fn.find("[data-slider-next]").on("click", function () {
                $(this).closest(".elm_item").find("[data-slider]").flickity('next');
            });

            $fn.on("click", "[data-editor-toggle]", function () {
                let $el = $(this);
                $el.closest(".elm_item").toggleClass("mod--active").find(".wrp_item_detail").stop(true).slideToggle(300);
            });

            $fn.on("change", "[data-editor-variant]", function () {
                let $el = $(this);
                $el.closest(".elm_item").addClass("mod--selected");
                configurator();
                if ($(this).closest(".elm_item").next().find("[data-slider]").hasClass("flickity-enabled")) {
                    $(this).closest(".elm_item").next().find("[data-slider]").flickity('resize')
                }
            });

            $fn.on("change", "[data-editor-option]", function () {
                window.location = '?selectedOption=' + $(this).val();
            });

            $fn.on("click", "[data-editor-confirm]", function (e) {
                e.preventDefault();
                if ($(this).prop('disabled')) {
                    alert('Pro vložení do košíku je potřeba navolit všechny komponenty');
                    return true;
                } else {
                    $.ajax({
                        method: 'post',
                        data: $('#editor-form').serialize()
                    });
                }
            });
        });

        $(document).on('change', '[data-count]', function () {
            configurator();
        });

        function configurator() {
            let currency = $('body').data('currency');
            let option = $('[name="selectedOption"]:checked').val();
            let $editorCanvas = $('[data-editor-canvas]');
            let code = [];
            let price = 0;
            $editorCanvas.empty();

            if (option === "1") {
                $editorCanvas.append('<img src="/img/single/kabel.png" style="z-index: 0">');
            } else if (option === "2") {
                $editorCanvas.append('<img src="/img/double/kabel.png" style="z-index: 0">');
            } else if (option === "3") {
                $editorCanvas.append('<img src="/img/tripple/kabel.png" style="z-index: 0">');
            }

            $('[data-editor-variant]:checked').each(function (index, item) {
                let $elm = $(this);
                let img = $elm.data('img');
                let zIndex = $elm.data('index');
                code.push($elm.data('code'));
                price += parseFloat($elm.data('price'));

                $editorCanvas.append('<img src="' + img + '" style="z-index: ' + zIndex + '">');
            });

            if (code.length > 0) {
                $editorCanvas.removeAttr('data-title');
            }

            $('[data-editor-code]').html($('[data-editor-code]').data('editor-code') + code.join(''));

            $.ajax({
                dataType: 'text',
                method: 'post',
                data: {do: 'calculatePrice', price: price}
            }).done(function (payload) {
                $('[data-editor-price]').html(payload);
            });

            $.ajax({
                dataType: 'text',
                method: 'post',
                data: {do: 'calculatePrice', price: price * parseInt($('[data-count]').val())}
            }).done(function (payload) {
                $('[data-editor-price-final]').html(payload);
            });

            $('[data-editor-confirm]').prop('disabled', false);
            $("#editor-form").find('.elm_item').each(function () {
                if (!$(this).hasClass('mod--selected')) {
                    $('[data-editor-confirm]').prop('disabled', true);
                }
            });
        }
    }

    function numberWithSpaces(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "&nbsp;");
    }
})(jQuery);
