(function ($) {
    fn_nl_lib_anchor();

    $(".lib--parallax").find("img:not(.lazyload)").parent().parallax();

    $(document).on('lazybeforeunveil', '.lazyload', function (e) {
        if ($(e.target).parent().hasClass("lib--parallax")) {
            $(e.target).parent().parallax();
        }
        $(e.target).one('load', function () {
            if ($(this).parent().hasClass("lib--lazyload")) {
                $(this).parent().removeClass("lib--lazyload").addClass("lib--lazyloaded");
            }
        });
    });

    nl_lib_dialog.init(function () {
        $(document).find(".lib--dialog .part_ui_btn").nl_lib_ripple();
        $(document).find(".lib--dialog .part_product_detail").part_product_detail();

    });
    $("[data-switch]").nl_lib_switch();
    $(".part_ui_btn").nl_lib_ripple();

    let $shadow_timeout;
    $(document).on({
        mouseenter: function () {
            if ($(window).width() > 1024) {
                $(this).closest(".wrp_comp_body").addClass("mod--hovered");
            }
        },
        mouseleave: function () {
            if ($(window).width() > 1024) {
                $(this).closest(".wrp_comp_body").removeClass("mod--hovered");
            }
        }
    }, '.part_item_product');

    var picture = document.createElement('picture'),
            strObj = '' + picture;
    if (strObj.indexOf("HTMLPictureElement") === -1 && !$("html").hasClass("ie")) {
        $.getScript($cdnjs.picturefill);
    }
    if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
        $html.addClass("ie11");
    }

    $("[data-lightagllery-dynamic]").on('click', function () {
        var elm = $(this);
        $(this).lightGallery({
            dynamic: true,
            thumbnail: false,
            fullScreen: false,
            zoom: true,
            actualSize: false,
            hash: false,
            share: false,
            download: true,
            autoplay: false,
            autoplayControls: false,
            dynamicEl: elm.data("lightagllery-dynamic")
        })
    });

    $("[data-lib-gallery]").each(function () {
        var elm = $(this);
        $(this).lightGallery({
            selector: "[data-lib-gallery-item]",
            thumbnail: false,
            fullScreen: false,
            zoom: true,
            actualSize: false,
            hash: false,
            share: false,
            download: true,
            autoplay: false,
            autoplayControls: false,
        });
    });

    $(document).on("click", "[data-basket-add]", function (e) {
        let header = $("#layout_header");

        if (header.hasClass("headroom--unpinned")) {
            header.removeClass("headroom--unpinned").addClass("headroom--pinned");
        }

        $("body").append('<div class="layout_basket_circle"></div>');

        let basket_circle = $(".layout_basket_circle");
        basket_circle.css({
            "top": e.clientY + "px",
            "left": e.clientX + "px"
        });

        basket_circle.animate({
            top: "1.5em",
            left: "99%",
            width: 16,
            height: 16,
            opacity: 0.1
        }, 800, function () {
            nl_lib_dialog.close();
            $html.on("click", function (e) {
                let cart = $("[data-toggle-basket]");
                if ($(e.target).closest('.part_basket_dropdown').length === 0 && $(e.target).closest(".elm_basket").length === 0) {
                    cart.removeClass("mod--active").next(".part_basket_dropdown").removeClass("mod--active");
                    $html.off('click');
                }
            });
            basket_circle.remove();
        });
    });

    if (window.location.hash === "#vr") {
        $("#layout_footer").addClass("mod--vr");
    }

    //basket delivery info toggle
    function showHideBasketDeliveryInfo()
    {
        var deliCheckBox = $('input[data-basket-delivery-toggle]');
        if (deliCheckBox.length) {
            var checkedDInfo = deliCheckBox.prop('checked');
            if (checkedDInfo) {
                $('[data-basket-delivery-info]').hide();
            } else {
                $('[data-basket-delivery-info]').show();
            }
        }
    }
    showHideBasketDeliveryInfo();

    $(document).on('change', 'input[data-basket-delivery-toggle]', function () {
        showHideBasketDeliveryInfo();
    });

    $(document).on('change', '[data-basket-company-toggle]', function () {
        $('[data-basket-company]').toggle($(this).prop('checked'));
    });

    $(document).on('change', '[data-select-billing-address]', function () {
        var addressParts = $(this).val().split('|');

        $('[name="billing[street]"]').val(addressParts[0]);
        $('[name="billing[city]"]').val(addressParts[1]);
        $('[name="billing[zip]"]').val(addressParts[2]);
        $('[name="billing[country]"]').val(addressParts[3]);
    });

    $(document).on('change', '[data-select-delivery-address]', function () {
        var addressParts = $(this).val().split('|');

        $('[name="delivery[street]"]').val(addressParts[0]);
        $('[name="delivery[city]"]').val(addressParts[1]);
        $('[name="delivery[zip]"]').val(addressParts[2]);
        $('[name="delivery[country]"]').val(addressParts[3]);
    });

    $(document).on('change', '#inputBillingCountry', function() {
        $('#inputBillingDicSk').toggle($(this).val() == 'SK');
    });

    if (typeof $html[0].style.grid !== 'string') {
        $html.addClass("no-grid");
    }

    $(document).on("click", `[data-click="footer#moreInfo"]`, function(e) {
        e.stopPropagation();

        let wsw = $(this).next(".part_ui_wsw");

        if (!wsw.hasClass("mod--active")) {
            $(this).addClass("mod--active");
            wsw.addClass("mod--active")
        } else {
            $(this).removeClass("mod--active");
            wsw.removeClass("mod--active")
        }

        $html.off("click.footerMoreInfo");
        $html.on("click.footerMoreInfo", function(e) {
            $html.off("click.footerMoreInfo");
            if (!$(e.target).closest(`[data-ref="moreInfo"]`).length && !$(e.target).closest(`[data-click="footer#moreInfo"]`).length) {
                $(`[data-ref="moreInfo"]`).removeClass("mod--active").prev().removeClass("mod--active")
            }
        })
    });

    if (getUrlParameter('showDialog') === 'login') {
        $('#login-button').click();
    }

    if ($('#showRegistrationSuccessDialog').length) {
        $.ajax({
            dataType: "json",
            url: '?do=registrationSuccessDialog'
        });
    }

    if ($('#showRegistrationSuccessDialog2').length) {
        $.ajax({
            dataType: "json",
            url: '?do=registrationSuccessDialog2'
        });
    }

    $(document).on('submit', '#registration-form', function () {
        $(this).find('[type=submit]').addClass('mod--loading');
    });

    $(document).on("click","[data-show-cc-settings]", function (e) {
        e.preventDefault();

        $("html").addClass("show--settings");
    })

    $(document).on("click","[data-copy-to-clipboard]", function (e) {
        e.preventDefault();
        var selector = $(this).data('copy-to-clipboard');
        navigator.clipboard.writeText($(selector).val());
        alert('Zkopírováno do schránky');
    })

    $(document).on('click', 'button[data-href]', function() {
        window.location.href = $(this).data('href')
    });
})(jQuery);

function getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};
