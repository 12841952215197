(function($) {
    const $fn = $(".part_table");

    if ($fn.length) {
        $fn.on("click", "[data-table-toggle]", function(){
            let $el = $(this);
            if ($el.hasClass("mod--active")) {
                $el.removeClass("mod--active mod--loading").closest("tr").next(".elm_table_detail").remove();
            } else {
                $el.addClass("mod--active mod--loading").closest("tr").find(".elm_action").not($el).removeClass("mod--active mod--loading").closest("tr").next(".elm_table_detail").remove();
                $.ajax({
                    dataType: 'html',
                    url: $el.data('table-toggle')
                }).done(function (data) {
                    $el.removeClass("mod--loading").closest("tr").after(data);
                });
            }
        });
        $fn.on("click", "[data-detail-close]", function(){
           $(this).closest(".elm_table_detail").remove();
            $fn.find("[data-table-toggle].mod--active").removeClass("mod--active mod--loading");
        });
    }
})(jQuery);