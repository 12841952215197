(function ($) {
    const $fn = $(".comp_tracking");

    if ($fn.length) {
        let progress = $fn.find("[data-ref-progress]");

        $fn.on("click", "[data-toggle-progress]", function (e) {
            e.preventDefault();
            $(this).toggleClass("mod--active");
            progress.stop().slideToggle();
        });

        $fn.on("click", '[data-toggle-transport]', function (e) {

            if (progress.hasClass("mod--transport-visible")) {
                progress.find('[data-transport-item]').stop().slideUp();
                progress.removeClass("mod--transport-visible");
                $(this).find('i').text($(this).data('text1'));
            } else {
                progress.find('[data-transport-item]').stop().slideDown({
                    start: function () {
                        $(this).css({
                            display: "flex"
                        })
                    }
                });
                progress.addClass("mod--transport-visible");
                $(this).find('i').text($(this).data('text2'));
            }
        });
    }
})(jQuery);